import { IBrandInfo } from "gatsby-theme-nurofen/src/components/common/PageSchema/models"

const extractor = (
  pageHref: string,
  { siteName, siteUrl, email, description, telephone, sameAs }: IBrandInfo
): object => {
  const url = new URL(pageHref)

  return {
    "@type": "Organization",
    name: siteName,
    url: siteUrl,
    logo: `${url.origin}/images/logo.png`,
    email,
    description,
    telephone,
    sameAs: sameAs?.[0],
  }
}

export default extractor
